import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Typography, CardActionArea, Modal } from '@material-ui/core';
import { RiFileWord2Fill, RiFileExcel2Fill, RiImageFill, RiFile3Fill } from "react-icons/ri";
import { FaFilePdf } from "react-icons/fa";
import SubCard from 'ui-component/cards/SubCard';
import ReactPlayer from 'react-player'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const FileBox = (props) => {
    const [file, setFile] = useState(null);
    const [marginTop, setMarginTop] = useState(props.mt);

    useEffect(() => {
        setFile(props.file);
    }, []);

    if (!file) {

        return (<>File is not provided.</>);

    } else {

        const filepath = file.path;
        var original_name = file.original_name;
        if (original_name === null || original_name === '') {
            original_name = filepath.split('/')[filepath.split('/').length-1].substr(37, 9999);
        }
        original_name = decodeURI(original_name);
    
        const iconSize = 36;
        var fileIcon = <RiFile3Fill size={iconSize} />;
        var padding = 0;
        switch (filepath.split('.').pop()) {
            default:
                fileIcon = <RiFile3Fill size={iconSize} />;
                padding = 2;
                break;
            case 'pdf':
                fileIcon = <FaFilePdf size={iconSize} color="#F40F02" />;
                padding = 2;
                break;
            case 'doc':
            case 'docx':
                fileIcon = <RiFileWord2Fill size={iconSize} color="#1e5299" />;
                padding = 2;
                break;
            case 'xls':
            case 'xlsx':
                fileIcon = <RiFileExcel2Fill size={iconSize} color="#00703d" />;
                padding = 2;
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                fileIcon =
                    <img
                        src={filepath}
                        style={{
                            width: '100%',
                            height: 180,
                            objectFit: 'cover',
                        }}
                    />;
                break;
            case 'mpeg':
            case 'mov':
            case 'mp4':
            case 'webm':
                fileIcon =
                    <ReactPlayer
                        className="screenany-video-player filebox"
                        url={filepath}
                        controls
                    />;
                break;
        }

        return (
            <>
                <CardActionArea
                    sx={{
                        mt: marginTop,
                        pt: padding,
                        textAlign: 'center',
                        boxShadow: 1,
                        backgroundColor: '#f6fafa'
                    }}
                    onClick={() => {window.open(filepath)}}
                >
                    {fileIcon}
                    <Typography
                        variant="subtitle2"
                        sx={{
                            mt: 0,
                            p: 1,
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        {original_name}
                    </Typography>
                </CardActionArea>
                {/*}
                <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                        }
                    }}
                    documents={[{ uri: 'https://screenany.s3.ap-east-1.amazonaws.com/7ed15de4-c221-4b6b-8108-537503f4a066.docx' }]}
                    style={{width: '100%', height: 400}}
                />
                */}
            </>
        );
    }
}

export default FileBox;
