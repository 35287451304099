import React, { useEffect, useState } from 'react';

import { Box, Typography, TextField, FormHelperText, Autocomplete } from '@material-ui/core';

import { uuidV4 } from 'utils/screenany';
import { property } from 'lodash';

const CustomField = (props) => {

    const [idKey, setIdKey] = useState(uuidV4());
    const [fieldData, setFieldData] = useState(null);

    useEffect(() => {
        // these 2 steps format the options and values for <Autocomplete>
        const options = props.fieldData.options.map((option) => {
            return {
                ...option,
                title: option.value
            };
        });
        const values = props.fieldData.values.map((value) => {
            for (const option of options) {
                if (value.toString() === option.id.toString()) {
                    return option;
                }
            }
            return value;
        });
        setFieldData({
            ...props.fieldData,
            options: options,
            values: values
        });
    }, []);

    const updateFieldValue = (fieldType, value) => {
        switch (fieldType) {
            case 'SINGLE_LINE':
            case 'MULTIPLE_LINE':
            case 'NUMERIC':
                setFieldData({
                    ...fieldData,
                    values: [value]
                });
                props.onUpdate(fieldData, [value]);
                break;
            case 'SINGLE_CHOICE':
                setFieldData({
                    ...fieldData,
                    values: [value]
                });
                props.onUpdate(fieldData, [value.id]);
                break;
            case 'MULTIPLE_CHOICE':
                setFieldData({
                    ...fieldData,
                    values: value
                });
                props.onUpdate(fieldData, value.map((v) => { return v.id; }));
                break;
        }
    };

    if (fieldData === null) {
        return (<></>);
    } else {
        return (
            <Box>
                {(fieldData.field_type === 'SINGLE_LINE') ? <>
                    <TextField key={idKey} label={fieldData.title} fullWidth defaultValue={fieldData.values[0]} onChange={(event) => {updateFieldValue('SINGLE_LINE', event.target.value)}} placeholder={fieldData.description} />
                    <FormHelperText>{fieldData.description}</FormHelperText>
                </> : <></>}
                {(fieldData.field_type === 'MULTIPLE_LINE') ? <>
                    <TextField key={idKey} label={fieldData.title} fullWidth multiline rows={5} maxRows={5} defaultValue={fieldData.values[0]} onChange={(event) => {updateFieldValue('MULTIPLE_LINE', event.target.value)}} placeholder={fieldData.description} />
                    <FormHelperText>{fieldData.description}</FormHelperText>
                </> : <></>}
                {(fieldData.field_type === 'NUMERIC') ? <>
                    <TextField key={idKey} label={fieldData.title} type="number" fullWidth defaultValue={fieldData.values[0]} onChange={(event) => {updateFieldValue('NUMERIC', event.target.value)}} placeholder={fieldData.description} />
                    <FormHelperText>{fieldData.description}</FormHelperText>
                </> : <></>}
                {(fieldData.field_type === 'SINGLE_CHOICE') ? <>
                    <Autocomplete
                        key={idKey}
                        options={fieldData.options}
                        getOptionLabel={(option) => option.title}
                        defaultValue={fieldData.values[0]}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={fieldData.title}
                                placeholder={fieldData.description}
                            />
                        )}
                        onChange={(event, value) => updateFieldValue('SINGLE_CHOICE', value)}
                    />
                    <FormHelperText>{fieldData.description}</FormHelperText>
                </> : <></>}
                {(fieldData.field_type === 'MULTIPLE_CHOICE') ? <>
                    <Autocomplete
                        multiple
                        key={idKey}
                        options={fieldData.options}
                        getOptionLabel={(option) => option.title}
                        defaultValue={fieldData.values}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={fieldData.title}
                                placeholder={fieldData.description}
                            />
                        )}
                        onChange={(event, value) => updateFieldValue('MULTIPLE_CHOICE', value)}
                    />
                    <FormHelperText>{fieldData.description}</FormHelperText>
                </> : <></>}
            </Box>
        );
    }

};

export default CustomField;
