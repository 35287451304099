import { useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// project imports
import NotFoundRoutes from './NotFoundRoutes';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
    // unlock corporation selector
    const dispatch = useDispatch();
    dispatch({
        type: '@screenanyUser/UPDATE_CORPORATION_LIST_ENABLED',
        payload: true
    });

    return useRoutes([NotFoundRoutes, LoginRoutes, MainRoutes]);
}
