const validation = (type, value) => {

    let valid = false;
    
    switch (type) {
        default: 
            valid = false;
            break;
        case 'email':
            valid = String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            break;
        case 'password':
            valid = true;
            if (value.length < 8) { 
                valid = false;
            } else if(value.search(/[a-z]/) < 0) { 
                valid = false;
            } else if(value.search(/[A-Z]/) < 0) { 
                valid = false;
            } else if(value.search(/[0-9]/) < 0) { 
                valid = false;
            }
            break;
    }

    return valid;

};

export default validation;
