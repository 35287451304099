import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import reducer from './reducer';
import { createLogger } from 'redux-logger';

// ===========================|| REDUX - MAIN STORE ||=========================== //

var store;
if (process.env.NODE_ENV === "development") {
    store = createStore(reducer, {}, applyMiddleware(createLogger()));
} else {
    store = createStore(reducer);
}
const persister = persistStore(store);

export { store, persister };
