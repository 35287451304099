import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// assets
import { Chip } from '@material-ui/core';
import * as icons from '@tabler/icons';

import { store } from 'store';
import { config, fetchApi } from 'utils/screenany';

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const ATSApplicationTitle = () => {
    const screenanyUser = useSelector((state) => state.screenanyUser);
    return (screenanyUser.unread_ats_application === 0) ?
        <>ATS Application</>
    :
        <div className="menu-item-title">
            <>ATS Application</>
            <Chip
                className="chip"
                label={screenanyUser.unread_ats_application}
                size="small"
                color="primary"
                sx={{
                    fontWeight: 200,
                    ml: 1
                }}
            />
        </div>
    ;
};

const VideoApplicationTitle = () => {
    const screenanyUser = useSelector((state) => state.screenanyUser);
    return (screenanyUser.unread_application === 0) ?
        <>Application</>
    :
        <div className="menu-item-title">
            <>Applications</>
            <Chip
                className="chip"
                label={screenanyUser.unread_application}
                size="small"
                color="error"
                sx={{
                    fontWeight: 200,
                    ml: 1
                }}
            />
        </div>
    ;
};

const Campaign = {
    id: 'screenany-menu-campaign',
    title: 'Campaign',
    type: 'group',
    children: [
        /*
        {
            id: 'collection',
            title: 'Collection',
            type: 'item',
            // url: '/collection',
            url: '/coming-soon',
            icon: icons.IconFolders,
            breadcrumbs: false
        },
        */
        {
            id: 'campaign',
            title: 'Jobs',
            type: 'item',
            url: '/campaign',
            icon: icons.IconFileText,
            breadcrumbs: false
        },
        /*
        {
            id: 'application-ats',
            title: <ATSApplicationTitle />,
            type: 'item',
            url: '/application-ats',
            icon: icons.IconListNumbers,
            breadcrumbs: false
        },
        */
        {
            id: 'application-video',
            title: <VideoApplicationTitle />,
            type: 'item',
            url: '/application-video',
            icon: icons.IconStack,
            breadcrumbs: false,
            chip: null,
        },
        /*
        {
            id: 'candidate',
            title: 'Candidate',
            type: 'item',
            // url: '/candidate',
            url: '/coming-soon',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'custom_field',
            title: 'Custom Field',
            type: 'item',
            url: '/field',
            icon: icons.IconColumns,
            breadcrumbs: false
        }
        */
    ]
};

export default Campaign;
