const screenanyUserReducer = (state = {
    id: 0,
    userrole_id: 0,
    userrole: { },
    corporation_id: 0,
    email: "",
    first_name: "",
    last_name: "",
    profile_picture: "",
    timezone: "Asia/Hong_Kong",
    remarks: "",
    corporations: [],
    corporation_select_list: [],
    corporation_select_list_enabled: true,
    current_corporation_id: 0,
    current_campaign_collection_id: -1,
    unread_application: 0,
    unread_ats_application: 0
}, action) => {
    const prefix = '@screenanyUser/';
    switch (action.type) {
        default:
            break;
        case `${prefix}UPDATE_USER`:
            state = {
                ...state,
                ...action.payload
            };
            break;
        case `${prefix}UPDATE_CURRENT_CORPORATION`:
            state = {
                ...state,
                current_corporation_id: action.payload
            };
            break;
        case `${prefix}UPDATE_CURRENT_CAMPAIGN_COLLECTION_ID`:
            state = {
                ...state,
                current_campaign_collection_id: action.payload
            };
            break;
        case `${prefix}UPDATE_CORPORATION_LIST_ENABLED`:
            state = {
                ...state,
                corporation_select_list_enabled: (typeof action.payload === 'boolean') ? action.payload : false
            };
            break;
        case `${prefix}UPDATE_UNREAD_APPLICATION`:
            state = {
                ...state,
                unread_application: action.payload
            };
            break;
        case `${prefix}UPDATE_UNREAD_ATS_APPLICATION`:
            state = {
                ...state,
                unread_ats_application: action.payload
            };
            break;
    }
    return state;
}

export default screenanyUserReducer;
