import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Dropzone from 'react-dropzone';
import UploadService from './FileUploadService';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
import { IconCloudUpload, IconCheck, IconTrash } from '@tabler/icons';

import { ComingSoonBox } from 'utils/screenany';
import { borderRadius } from '@material-ui/system';

const useStyles = makeStyles({
    uploadBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 100,
        background: '#f6f6f6',
        borderWidth: 2,
        borderRadius: 5,
        borderColor: '#dddddd',
        borderStyle: 'dotted',
        textAlign: 'center'
    },
    uploadBoxOver: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 100,
        background: '#f0f0f0',
        borderWidth: 2,
        borderRadius: 5,
        borderColor: '#cccccc',
        borderStyle: 'dotted',
        textAlign: 'center',
        cursor: 'pointer'
    },
});

const UploadBox = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const defaultUploadDescription = props.defaultUploadDescription ?? 'Drop or pick a file here.';
    const overUploadDescription = props.overUploadDescription ?? 'Release to upload.';
    const [uploadDescription, setUploadDescription] = useState('');
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [isUploadStarted, setIsUploadStarted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [uploadResult, setUploadResult] = useState(null);
    const [styleName, setStyleName] = useState(classes.uploadBox);

    const overFileHandler = () => {
        if (!isUploadStarted) {
            setUploadDescription(overUploadDescription);
            setStyleName(classes.uploadBoxOver);
        }
    };
    const leaveFileHandler = () => {
        setUploadDescription(defaultUploadDescription);
        setStyleName(classes.uploadBox);
    };
    const dropFileHandler = (acceptedFiles) => {
        if (!isUploadStarted) {
            setSelectedFiles(acceptedFiles);
        }
    };

    const dropRejectedHandler = () => {
        dispatch({
            type: '@snackbar/SNACKBAR_OPEN',
            open: true,
            variant: 'alert',
            alertSeverity: 'error',
            autoHideDuration: 3000,
            message: 'Invalid file format.'
        });
        setUploadDescription(defaultUploadDescription);
        setStyleName(classes.uploadBox);
    };

    useEffect(() => {
        if (selectedFiles !== undefined) {
            let currentFile = selectedFiles[0];
            setProgress(0);
            setIsUploadStarted(true);
            if (props.startUploading !== undefined) {
                props.startUploading();
            }
            setCurrentFile(currentFile);
            UploadService.upload(currentFile, (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            }).then((response) => {
                setUploadDescription('File is uploaded successfully!')
                setUploadResult(response.data);
                if (props.finishUploading !== undefined) {
                    props.finishUploading(response.data);
                }
            }).catch(() => {
                setProgress(0);
                setCurrentFile(undefined);
            });
            setSelectedFiles(undefined);
        }
    }, [selectedFiles]);
    useEffect(() => {
        setUploadDescription(`Uploading...${progress}%`);
    }, [progress]);
    useEffect(() => {
        setUploadDescription(defaultUploadDescription);
        setStyleName(classes.uploadBox);
    }, []);

    return (
    <>
    {
        <Dropzone
            accept={props.acceptFormats}
            maxFiles={1}
            multiple={false}
            disabled={isUploadStarted || props.disabled}
            onDragOver={() => {overFileHandler()}}
            onDragLeave={() => {leaveFileHandler()}}
            onDropAccepted={(acceptedFiles) => dropFileHandler(acceptedFiles)}
            onDropRejected={() => {dropRejectedHandler()}}
        >
            {({getRootProps, getInputProps}) => (
                <div
                    {...getRootProps()}
                    className={styleName}
                    onMouseOver={() => {
                        if (!isUploadStarted) {
                            setStyleName(classes.uploadBoxOver)}}
                        }
                    onMouseOut={() => {setStyleName(classes.uploadBox)}}
                    style={{
                        width: props.width,
                        height: props.height,
                    }}
                >
                    <input {...getInputProps()} />
                    {(currentFile === undefined) ?
                        <IconCloudUpload strokeWidth={1} />
                    :
                        (uploadResult) ?
                            <IconCheck strokeWidth={1} />
                        :
                            <CircularProgress size={24} variant="determinate" value={progress} />
                    }
                    <MuiTypography>{uploadDescription}</MuiTypography>
                </div>
            )}
        </Dropzone>
    }
    </>
    );
}

export default UploadBox;
