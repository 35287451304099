import React from 'react';

// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import * as icons from '@tabler/icons';

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const User = {
    id: 'screenany-menu-account',
    title: 'Corporation',
    type: 'group',
    children: [
        // {
        //     id: 'corporation',
        //     title: 'Corporation Settings',
        //     type: 'item',
        //     url: '/corporation',
        //     icon: icons.IconBuilding,
        //     breadcrumbs: false
        // },
        {
            id: 'frontpage',
            title: 'Front Page Settings',
            type: 'item',
            url: '/front-page',
            icon: icons.IconLayout2,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: 'User',
            type: 'item',
            url: '/user',
            icon: icons.IconUser,
            breadcrumbs: false
        }
    ]
};

export default User;
