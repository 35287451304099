import { store } from 'store/index.js';
import moment from 'moment-timezone';

const datetimeHandler = {

    toLocal: (dt) => {
        const state = store.getState();
        // datetime retrieve from db
        const datetime = moment(dt);
        return datetime.tz(state.screenanyUser.timezone);
    },

    toUTC: (dt) => {
        // datetime pass to db
        const datetime = moment(dt);
        return datetime.tz('UTC').format();
    }

};

export default datetimeHandler;
