import { store } from 'store/index.js';

const fetchApi = async (url, body={}, method='POST', headers={}) => {
    const state = store.getState();
    return await fetch(url, {
        method: method,
        body: JSON.stringify({
            ...body,
            corporation_id: state.screenanyUser.current_corporation_id
        }),
        headers: new Headers({
            ...headers,
            'Content-Type': 'application/json',
            'Screenany-Access-Token': (localStorage.getItem('screenany_access_token') ? localStorage.getItem('screenany_access_token') : '')
        })
    }).then((response) => {
        return response.json();
    }).then((responseData) => {
        return responseData;
    }).catch((error) => {
        // console.error(error);
        return {
            data: []
        }
    });
};

export default fetchApi;
