import { Backdrop, CircularProgress } from '@material-ui/core';

const BackDrop = (props) => {
    return (
        <Backdrop
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
        >
            <CircularProgress sx={{ color: '#000000' }} />
        </Backdrop>
    )
}

export default BackDrop;
