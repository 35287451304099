import React, { useEffect, useState } from 'react';

import styles from './index.module.css';

const ProgressBar = (props) => {
    const [number, setNumber] = useState(props.number || 0);
    const [total, setTotal] = useState(props.total || 0);
    const [isPercentage, setIsPercentage] = useState(props.isPercentage); // percentage or fraction
    const [percentage, setPercentage] = useState(0);
    const [status, setStatus] = useState('low');
    const [value, setValue] = useState('-/-'); // percentage or fraction

    useEffect(() => {
        if (total === 0) {
            setValue((isPercentage) ? '0.00%' : '-');
        } else {
            const percentage = (number / total * 100).toFixed(2);
            setPercentage(percentage);
            if (percentage > 33) { setStatus('medium'); }
            if (percentage > 66) { setStatus('high'); }
            if (isPercentage) {
                setValue(percentage + '%');
            } else {
                setValue(number + ' / ' + total);
            }
        }    
    }, [number, total, isPercentage]);

    return (
        <div className={styles.progressbarContainer} data-status={status}>
            <div className={styles.progressbarInner}>
                <div className={styles.progressbarPercentage} style={{ width: percentage+'%' }}></div>
                <div className={styles.progressbarValue}>{value}</div>
            </div>
        </div>
    );
}

export default ProgressBar;
