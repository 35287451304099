import http from './http';
import { config } from 'utils/screenany';

const upload = (file, onUploadProgress) => {
    let formData = new FormData();
    formData.append('file', file);
    return http.post(`${config.apiBaseUrl}/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Screenany-Access-Token': (localStorage.getItem('screenany_access_token') ? localStorage.getItem('screenany_access_token') : '')
        },
        onUploadProgress,
    });
};

const getFiles = () => {
    // return http.get("/files");
};

export default {
    upload,
    getFiles,
};
