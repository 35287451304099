import Root from './root';
import Screenany from './screenany';
import Account from './account';
import Campaign from './campaign';
import Billing from './billing';
import Simple from './simple';
// import Other from './other';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = () => {
    return {
        // items: [Root, Campaign, Billing],
        // admin_items: [Root, Screenany, Account, Campaign, Billing]
        items: [Root, Simple],
        admin_items: [Root, Screenany]
    };
};

export default menuItems();
