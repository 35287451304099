import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
    const screenanyUser = useSelector((state) => state.screenanyUser);
    const menu_items = (screenanyUser.userrole_id.toString() === "1") ? menuItem.admin_items : menuItem.items;
    const navItems = menu_items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
