const getUrlParam = (paramName) => {
    if (paramName.trim() === '') {
        return null;
    }
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get(paramName);
    return c;
};

export default getUrlParam;
