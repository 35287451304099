const screenanyDataReducer = (state = {
    userroles: [],
}, action) => {
    const prefix = '@screenanyData/';
    switch (action.type) {
        default:
            break;
        case `${prefix}UPDATE_DATA`:
            state = {
                ...state,
                ...action.payload
            };
            break;
        case `${prefix}UPDATE_USERROLES`:
            state = {
                ...state,
                userroles: action.payload
            };
            break;
    }
    return state;
}

export default screenanyDataReducer;
