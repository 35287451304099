import React, { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const Login = Loadable(lazy(() => import('views/guest/login')));
const LoggedOut = Loadable(lazy(() => import('views/guest/logged-out')));

// ===========================|| AUTH ROUTING ||=========================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: (
                <NavMotion>
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                </NavMotion>
            )
        },
        {
            path: '/login',
            element: (
                <NavMotion>
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                </NavMotion>
            )
        },
        {
            path: '/logged-out',
            element: (
                <NavMotion>
                    <LoggedOut />
                </NavMotion>
            )
        }
    ]
};

export default LoginRoutes;
