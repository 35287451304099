import React, { useEffect, useState } from 'react';

import { Grid, FormControl, InputLabel, Button, IconButton, OutlinedInput, InputAdornment, InputBase, Paper, Divider } from '@material-ui/core';
import { IconCirclePlus, IconCircleMinus, IconArrowNarrowUp, IconArrowNarrowDown } from '@tabler/icons';

import { arrayItemMove } from 'utils/screenany';

const TextInputBox = (props) => {
    const optionData = props.optionData;
    const moveUp = (props.moveUp == null) ? false : props.moveUp;
    const moveDown = (props.moveDown == null) ? false : props.moveDown;

    return (
        <FormControl sx={{ width: '100%', mb: 0.5 }} variant="outlined">
            {/* <InputLabel htmlFor="outlined-adornment-password"></InputLabel> */}
            <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                value={optionData.value}
                size="small"
                placeholder="Option value"
                onChange={(event) => {props.onChange(optionData.id, event.target.value)}}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            disabled={!moveUp}
                            onClick={() => {props.onMoveUp(optionData.id)}}
                            edge="end"
                        >
                            <IconArrowNarrowUp strokeWidth={1.5} size="20" />
                        </IconButton>
                        <IconButton
                            disabled={!moveDown}
                            onClick={() => {props.onMoveDown(optionData.id)}}
                            edge="end"
                        >
                            <IconArrowNarrowDown strokeWidth={1.5} size="20" />
                        </IconButton>
                        <IconButton
                            onClick={() => {props.onDeleteOption(optionData.id)}}
                            edge="end"
                        >
                            <IconCircleMinus strokeWidth={1.5} size="20" />
                        </IconButton>
                    </InputAdornment>
                }
                /* label="" */
            />
        </FormControl>
    );
};

const OptionList = (props) => {

    const [isFirstTime, setIsFirstTime] = useState(true);
    const [options, setOptions] = useState(props.optionData || []);
    const [deletedOptions, setDeletedOptions] = useState(props.deletedOptionData || []);
    const [tempID, setTempID] = useState(0);

    useEffect(() => {
        setOptions(props.optionData);
    }, [props.optionData]);

    const addOptionHandler = () => {
        setOptions((options) => {
            return [
                ...options,
                {
                    id: tempID - 1,
                    value: '',
                    ordering: null
                }
            ];
        });
        setTempID((tempID) => tempID - 1);
    };

    const moveOptionHandler = (id, up=true) => {
        // find index
        var i = 0;
        options.forEach((option, index) => {
            if (option.id.toString() === id.toString()) {
                i = index;
            }
        });
        var newOptions = options.slice(); // duplicate the array for re-position
        newOptions = arrayItemMove(newOptions, i, (up) ? i-1 : i+1);
        setOptions(newOptions);
    };
    const deleteOptionHandler = (id) => {
        // take out from the option list
        var newOptions = options.slice();
        newOptions = newOptions.filter((option) => {
            return option.id.toString() !== id.toString()
        });
        // add to the deleted list
        setDeletedOptions([
            ...deletedOptions,
            id
        ]);
        setOptions(newOptions);
    };

    const updateOptionaHandler = (id, value) => {
        var newOptions = options.slice();
        newOptions = newOptions.map((option) => {
            return (option.id.toString() !== id.toString()) ?
                option
            : {
                ...option,
                value: value
            };
        });
        setOptions(newOptions);
    };

    useEffect(() => {
        if (!isFirstTime) {
            props.onChangeOptionList({
                options: options,
                deletedOptions: deletedOptions
            });
        }
        setIsFirstTime(false);
    }, [options]);

    if (props.optionData === undefined) {
        return <></>;
    } else {
        return (
            <>
                <Grid container sx={{ mb: 1 }}>
                    <Grid item lg={12}>
                        {options.map((option, index) => {
                            return (
                                <TextInputBox
                                    key={option.id}
                                    optionData={option}
                                    moveUp={(index > 0)}
                                    moveDown={(index+1 < options.length)}
                                    onChange={(id, value) => updateOptionaHandler(id, value)}
                                    onMoveUp={(id) => {moveOptionHandler(id, true)}}
                                    onMoveDown={(id) => {moveOptionHandler(id, false)}}
                                    onDeleteOption={(id) => {deleteOptionHandler(id)}}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item>
                        <Button
                            onClick={() => {addOptionHandler()}}
                            startIcon={<IconCirclePlus strokeWidth={1} />}
                        >
                            Add an option
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }
};

export default OptionList;
