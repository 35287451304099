import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// assets
import { Chip } from '@material-ui/core';
import * as icons from '@tabler/icons';

import { store } from 'store';
import { config, fetchApi } from 'utils/screenany';

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const VideoApplicationTitle = () => {
    const screenanyUser = useSelector((state) => state.screenanyUser);
    return (screenanyUser.unread_application === 0) ?
        <>Application</>
    :
        <div className="menu-item-title">
            <>Applications</>
            <Chip
                className="chip"
                label={screenanyUser.unread_application}
                size="small"
                color="error"
                sx={{
                    fontWeight: 200,
                    ml: 1
                }}
            />
        </div>
    ;
};

const Campaign = {
    id: 'screenany-menu-campaign',
    title: '', // Simple Menu
    type: 'group',
    children: [
        {
            id: 'campaign',
            title: 'Jobs',
            type: 'item',
            url: '/campaign',
            icon: icons.IconFileText,
            breadcrumbs: false
        },
        {
            id: 'application-video',
            title: <VideoApplicationTitle />,
            type: 'item',
            url: '/application-video',
            icon: icons.IconStack,
            breadcrumbs: false,
            chip: null,
        },
        // {
        //     id: 'payment',
        //     title: 'Payment',
        //     type: 'item',
        //     // url: '/payment',
        //     url: '/coming-soon',
        //     icon: icons.IconCoin,
        //     breadcrumbs: false
        // }
    ]
};

export default Campaign;
