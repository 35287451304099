import React from 'react';

// assets
import * as icons from '@tabler/icons';

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const Screenany = {
    id: 'screenany-menu-screenany',
    title: 'ScreenAny Zone',
    type: 'group',
    children: [
        {
            id: 'superuser',
            title: 'Super User',
            type: 'item',
            url: '/super-user',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'client',
            title: 'Client',
            type: 'item',
            url: '/client',
            icon: icons.IconMoodSmile,
            breadcrumbs: false
        },
        // {
        //     id: 'finance',
        //     title: 'Finance',
        //     type: 'item',
        //     // url: '/finance',
        //     url: '/coming-soon',
        //     icon: icons.IconCoin,
        //     breadcrumbs: false
        // },
        {
            id: 'test-page',
            title: 'Test Page',
            type: 'item',
            url: '/test-page',
            icon: icons.IconStethoscope,
            breadcrumbs: false
        }
    ]
};

export default Screenany;
