import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

import { getLogo } from 'utils/screenany';

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => {
    const ScreenanyLogo = getLogo('h', 140);
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            {/* <Logo /> */}
            { ScreenanyLogo }
        </ButtonBase>
    );
};

export default LogoSection;
