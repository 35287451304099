import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { snackbar } from 'utils/screenany';

const useStyles = makeStyles({
    container: {
        position: 'relative',
        display: 'block',
        backgroundColor: '#f8f8f8',
        padding: '10% 0',
        border: '3px dashed #b0b0b0',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
        },
    },
    containerDisabled: {
        position: 'relative',
        display: 'block',
        backgroundColor: '#f8f8f8',
        padding: '10% 0',
        border: '3px dashed #b0b0b0',
        overflow: 'hidden',
        cursor: 'default',
        opacity: 0.6,
    },
    text: {
        textAlign: 'center',
        color: '#aaa',
    },
    input: {
        position: 'absolute',
        left: '-999999px',
    },
});

const FilePickerBox = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [maxFileCount, setMaxFileCount] = useState(props.maxFileCount || 10);
    const [singleFileSizeLimit, setSingleFileSizeLimit] = useState(props.singleFileSizeLimit || 2); // in MB
    const [totalFileSizeLimit, setTotalFileSizeLimit] = useState(props.totalFileSizeLimit || 10); // in MB
    const [text, setText] = useState('Click here to select files');
    const [selectedFiles, setSelectFiles] = useState([]);

    useEffect(() => {
        if (selectedFiles.length > 0) {
            setText(`You have selected ${selectedFiles.length} file${selectedFiles.length>1?'s':''}`);
        } else {
            setText(`Click here to select files`);
        }
    }, [selectedFiles]);
    
    const chooseFileHandler = (event) => {
        const files = event.target.files;
        // number of file checking
        if (files.length > maxFileCount) {
            dispatch(snackbar('error', `You can only choose up to ${maxFileCount} file${maxFileCount>1?'s':''}.`));
            setSelectFiles([]);
            if (props.onChange) {
                props.onChange([]);
            }
            return false;
        }
        // single file size checking
        var totalFileSize = 0;
        for (const file of files) {
            if (file.size / 1024 / 1024 > singleFileSizeLimit) {
                dispatch(snackbar('error', `The size of each file cannot exceed ${singleFileSizeLimit} MB.`));
                setSelectFiles([]);
                if (props.onChange) {
                    props.onChange([]);
                }
                return false;
            }
            totalFileSize += file.size;
        }
        if (totalFileSize / 1024 / 1024 > totalFileSizeLimit) {
            dispatch(snackbar('error', `The total size of selected file cannot exceed ${totalFileSizeLimit} MB.`));
            setSelectFiles([]);
            if (props.onChange) {
                props.onChange([]);
            }
            return false;
        }
        setSelectFiles(files);
        if (props.onChange) {
            props.onChange(files);
        }
    };

    return (
        <label className={props.disabled?classes.containerDisabled:classes.container}>
            <Typography className={classes.text}>{text}</Typography>
            <input type="file" className={classes.input} onChange={(event) => {chooseFileHandler(event)}} multiple disabled={props.disabled} accept={props.accept} />
        </label>
    );
};

export default FilePickerBox;