const config = {
    siteName: 'ScreenAny',
    separator: ' - ',
    apiBaseUrl: 'https://api.screenany.com/api/v1',
    frontendURL: 'https://jobs.screenany.com',
    videoForm: {
        question: {
            types: [
                { id: 'OPEN_ENDED', value: 'Open-Ended' },
                { id: 'NUMERIC', value: 'Number' },
                { id: 'SINGLE_CHOICE', value: 'Single Selection' },
                { id: 'MULTIPLE_CHOICE', value: 'Multiple Selection' },
                { id: 'DATETIME_PICKER', value: 'Date & Time' },
                { id: 'DATE_PICKER', value: 'Date' },
                { id: 'TIME_PICKER', value: 'Time' },
                { id: 'FILE', value: 'File' },
            ]
        }
    },
    presetVideoFormQuestions: [
        {
            answer_type: "SINGLE_CHOICE",
            description: "",
            is_video: 0,
            name: "Total year(s) of experience",
            openended_audio: 1,
            openended_text: 1,
            openended_video: 1,
            ordering: 1,
            params: "{\"video_fit\":0,\"mandatory\":1,\"video_length\":60,\"audio_length\":60}",
            question_options: [
                {
                    ordering: 1,
                    value: "Fresh Graduate (畢業生)"
                },
                {
                    ordering: 2,
                    value: "1-5 Years (1-5 年)"
                },
                {
                    ordering: 3,
                    value: "6-10 Years (6-10 年)"
                },
                {
                    ordering: 4,
                    value: "11-20 Years (11-20 年)"
                },
                {
                    ordering: 5,
                    value: "> 20 Years (20 年)"
                }
            ],
            remark: "",
            tags: [],
            title: "Total year(s) of experience (你有多少年工作經驗呢?)"
        },
        {
            answer_type: "OPEN_ENDED",
            description: "Example 1: 20000/month (Full time)\nExample 2: $60/hour (Part time)",
            is_video: 0,
            name: "Expected Salary",
            openended_audio: 0,
            openended_text: 1,
            openended_video: 0,
            ordering: 2,
            params: "{\"video_fit\":0,\"mandatory\":1,\"video_length\":60,\"audio_length\":60}",
            question_options: [],
            remark: "",
            tags: [],
            title: "What is your expected salary? (你的期望薪金是多少?)"
        },
        {
            answer_type: "SINGLE_CHOICE",
            description: "",
            is_video: 0,
            name: "Living Location",
            openended_audio: 0,
            openended_text: 1,
            openended_video: 0,
            ordering: 3,
            params: "{\"video_fit\":0,\"mandatory\":1,\"video_length\":60,\"audio_length\":60}",
            question_options: [
                {
                    ordering: 1,
                    value: "Kwai Chung (NT West)"
                },
                {
                    ordering: 2,
                    value: "Tsing Yi (NT West)"
                },
                {
                    ordering: 3,
                    value: "Tsuen Wan (NT West)"
                },
                {
                    ordering: 4,
                    value: "Tuen Mun (NT West)"
                },
                {
                    ordering: 5,
                    value: "Tin Shui Wai (NT West)"
                },
                {
                    ordering: 6,
                    value: "Yuen Long (NT West)"
                },
                {
                    ordering: 7,
                    value: "Tseung Kwan O (NT East)"
                },
                {
                    ordering: 8,
                    value: "Sai Kung (NT East)"
                },
                {
                    ordering: 9,
                    value: "Shatin (NT East)"
                },
                {
                    ordering: 10,
                    value: "Ma On Shan (NT East)"
                },
                {
                    ordering: 11,
                    value: "Tai Po (NT East)"
                },
                {
                    ordering: 12,
                    value: "Fanling & Sheung Shui (NT East)"
                },
                {
                    ordering: 13,
                    value: "Hunghom (Kowloon)"
                },
                {
                    ordering: 14,
                    value: "Kowloon Tong (Kowloon)"
                },
                {
                    ordering: 15,
                    value: "Kowloon City (Kowloon)"
                },
                {
                    ordering: 16,
                    value: "Kowloon Bay (Kowloon)"
                },
                {
                    ordering: 17,
                    value: "Kwun Tong (Kowloon)"
                },
                {
                    ordering: 18,
                    value: "Mei Foo (Kowloon)"
                },
                {
                    ordering: 19,
                    value: "Sham Shui Po (Kowloon)"
                },
                {
                    ordering: 20,
                    value: "West Kowloon (Kowloon)"
                },
                {
                    ordering: 21,
                    value: "Wong Tai Sin (Kowloon)"
                },
                {
                    ordering: 22,
                    value: "Yau Tsim Mong (Kowloon)"
                },
                {
                    ordering: 23,
                    value: "Aberdeen (Hong Kong Island)"
                },
                {
                    ordering: 24,
                    value: "Central (Hong Kong Island)"
                },
                {
                    ordering: 25,
                    value: "Island East (Hong Kong Island)"
                },
                {
                    ordering: 26,
                    value: "Island South (Hong Kong Island)"
                },
                {
                    ordering: 27,
                    value: "Island West (Hong Kong Island)"
                },
                {
                    ordering: 28,
                    value: "Mid Levels / Peak (Hong Kong Island)"
                },
                {
                    ordering: 29,
                    value: "Pok Fu Lam (Hong Kong Island)"
                },
                {
                    ordering: 30,
                    value: "Wan Chai /Causeway Bay (Hong Kong Island)"
                },
                {
                    ordering: 31,
                    value: "Lantau"
                }
            ],
            remark: "",
            tags: [],
            title: "Where do you live?"
        }
    ],
    customField: {
        types: [
            { id: 'SINGLE_LINE', value: 'Single Line' },
            { id: 'MULTIPLE_LINE', value: 'Multiple Line' },
            { id: 'NUMERIC', value: 'Numeric' },
            { id: 'SINGLE_CHOICE', value: 'Single Selection' },
            { id: 'MULTIPLE_CHOICE', value: 'Multiple Selection' },
            { id: 'DATETIME_PICKER', value: 'Date-Time Picker' },
            { id: 'DATE_PICKER', value: 'Date Picker' },
            { id: 'TIME_PICKER', value: 'Time Picker' },
        ],
        scopes: [
            { id: 'campaign', value: 'Campaign' },
            { id: 'candidate', value: 'Candidate' },
        ]
    },
    internalTags: [
        { id: 'first',      label: 'first' },
        { id: 'second',     label: 'second' },
    ],
    applicationStatuses: [
        { id: 'NEW',            label: 'New' },
        { id: 'SHORTLISTED',    label: 'Shortlisted' },
        { id: 'OFFERED',        label: 'Offered' },
        { id: 'REJECTED',       label: 'Rejected' },
        { id: 'ARCHIVED',       label: 'Archived' },
        { id: 'BOOKMARKED',     label: 'Bookmarked' },
    ],
    messageTypes: [
        { id: 'invitation',    label: 'Invitation' },
        { id: 'notification',  label: 'Notification' },
        { id: 'complete',      label: 'Complete' },
    ],
    messageLanguages: [
        { id: 'zh-HK',      label: 'Cantonese', },
        { id: 'en-GB',      label: 'English', },
    ],
    messageChannelsJobDetail: [
        { id: 'nosend',     label: 'On-demand', },
        { id: 'email',      label: 'Email (Auto)', },
        { id: 'whatsapp',   label: 'WhatsApp (Auto)', },
        { id: 'sms',        label: 'SMS (Auto)', },
    ],
    messageChannels: [
        { id: 'nosend',     label: 'On-demand', },
        { id: 'email',      label: 'Email', },
        { id: 'whatsapp',   label: 'WhatsApp', },
        { id: 'sms',        label: 'SMS', },
    ],
};

export default config;
