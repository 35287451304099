const timeZones2 = [
   {
      "value":"Etc/GMT+12",
      "offset":-12,
      "tz":"UTC-12:00",
      "id":1
   },
   {
      "value":"Etc/GMT+11",
      "offset":-11,
      "tz":"UTC-11:00",
      "id":2
   },
   {
      "value":"Pacific/Midway",
      "offset":-11,
      "tz":"UTC-11:00",
      "id":3
   },
   {
      "value":"Pacific/Niue",
      "offset":-11,
      "tz":"UTC-11:00",
      "id":4
   },
   {
      "value":"Pacific/Pago_Pago",
      "offset":-11,
      "tz":"UTC-11:00",
      "id":5
   },
   {
      "value":"Etc/GMT+10",
      "offset":-10,
      "tz":"UTC-10:00",
      "id":6
   },
   {
      "value":"Pacific/Honolulu",
      "offset":-10,
      "tz":"UTC-10:00",
      "id":7
   },
   {
      "value":"Pacific/Johnston",
      "offset":-10,
      "tz":"UTC-10:00",
      "id":8
   },
   {
      "value":"Pacific/Rarotonga",
      "offset":-10,
      "tz":"UTC-10:00",
      "id":9
   },
   {
      "value":"Pacific/Tahiti",
      "offset":-10,
      "tz":"UTC-10:00",
      "id":10
   },
   {
      "value":"America/Anchorage",
      "offset":-8,
      "tz":"UTC-09:00",
      "id":11
   },
   {
      "value":"America/Juneau",
      "offset":-8,
      "tz":"UTC-09:00",
      "id":12
   },
   {
      "value":"America/Nome",
      "offset":-8,
      "tz":"UTC-09:00",
      "id":13
   },
   {
      "value":"America/Sitka",
      "offset":-8,
      "tz":"UTC-09:00",
      "id":14
   },
   {
      "value":"America/Yakutat",
      "offset":-8,
      "tz":"UTC-09:00",
      "id":15
   },
   {
      "value":"America/Santa_Isabel",
      "offset":-7,
      "tz":"UTC-08:00",
      "id":16
   },
   {
      "value":"America/Los_Angeles",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":17
   },
   {
      "value":"America/Tijuana",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":18
   },
   {
      "value":"America/Vancouver",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":19
   },
   {
      "value":"America/Los_Angeles",
      "offset":-8,
      "tz":"UTC-08:00",
      "id":20
   },
   {
      "value":"America/Tijuana",
      "offset":-8,
      "tz":"UTC-08:00",
      "id":21
   },
   {
      "value":"America/Vancouver",
      "offset":-8,
      "tz":"UTC-08:00",
      "id":22
   },
   {
      "value":"PST8PDT",
      "offset":-8,
      "tz":"UTC-08:00",
      "id":23
   },
   {
      "value":"America/Creston",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":24
   },
   {
      "value":"America/Dawson",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":25
   },
   {
      "value":"America/Dawson_Creek",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":26
   },
   {
      "value":"America/Hermosillo",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":27
   },
   {
      "value":"America/Phoenix",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":28
   },
   {
      "value":"America/Whitehorse",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":29
   },
   {
      "value":"Etc/GMT+7",
      "offset":-7,
      "tz":"UTC-07:00",
      "id":30
   },
   {
      "value":"America/Chihuahua",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":31
   },
   {
      "value":"America/Mazatlan",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":32
   },
   {
      "value":"America/Boise",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":33
   },
   {
      "value":"America/Cambridge_Bay",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":34
   },
   {
      "value":"America/Denver",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":35
   },
   {
      "value":"America/Edmonton",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":36
   },
   {
      "value":"America/Inuvik",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":37
   },
   {
      "value":"America/Ojinaga",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":38
   },
   {
      "value":"America/Yellowknife",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":39
   },
   {
      "value":"MST7MDT",
      "offset":-6,
      "tz":"UTC-07:00",
      "id":40
   },
   {
      "value":"America/Belize",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":41
   },
   {
      "value":"America/Costa_Rica",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":42
   },
   {
      "value":"America/El_Salvador",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":43
   },
   {
      "value":"America/Guatemala",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":44
   },
   {
      "value":"America/Managua",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":45
   },
   {
      "value":"America/Tegucigalpa",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":46
   },
   {
      "value":"Etc/GMT+6",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":47
   },
   {
      "value":"Pacific/Galapagos",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":48
   },
   {
      "value":"America/Chicago",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":49
   },
   {
      "value":"America/Indiana/Knox",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":50
   },
   {
      "value":"America/Indiana/Tell_City",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":51
   },
   {
      "value":"America/Matamoros",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":52
   },
   {
      "value":"America/Menominee",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":53
   },
   {
      "value":"America/North_Dakota/Beulah",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":54
   },
   {
      "value":"America/North_Dakota/Center",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":55
   },
   {
      "value":"America/North_Dakota/New_Salem",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":56
   },
   {
      "value":"America/Rainy_River",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":57
   },
   {
      "value":"America/Rankin_Inlet",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":58
   },
   {
      "value":"America/Resolute",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":59
   },
   {
      "value":"America/Winnipeg",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":60
   },
   {
      "value":"CST6CDT",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":61
   },
   {
      "value":"America/Bahia_Banderas",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":62
   },
   {
      "value":"America/Cancun",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":63
   },
   {
      "value":"America/Merida",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":64
   },
   {
      "value":"America/Mexico_City",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":65
   },
   {
      "value":"America/Monterrey",
      "offset":-5,
      "tz":"UTC-06:00",
      "id":66
   },
   {
      "value":"America/Regina",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":67
   },
   {
      "value":"America/Swift_Current",
      "offset":-6,
      "tz":"UTC-06:00",
      "id":68
   },
   {
      "value":"America/Bogota",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":69
   },
   {
      "value":"America/Cayman",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":70
   },
   {
      "value":"America/Coral_Harbour",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":71
   },
   {
      "value":"America/Eirunepe",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":72
   },
   {
      "value":"America/Guayaquil",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":73
   },
   {
      "value":"America/Jamaica",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":74
   },
   {
      "value":"America/Lima",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":75
   },
   {
      "value":"America/Panama",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":76
   },
   {
      "value":"America/Rio_Branco",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":77
   },
   {
      "value":"Etc/GMT+5",
      "offset":-5,
      "tz":"UTC-05:00",
      "id":78
   },
   {
      "value":"America/Detroit",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":79
   },
   {
      "value":"America/Havana",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":80
   },
   {
      "value":"America/Indiana/Petersburg",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":81
   },
   {
      "value":"America/Indiana/Vincennes",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":82
   },
   {
      "value":"America/Indiana/Winamac",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":83
   },
   {
      "value":"America/Iqaluit",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":84
   },
   {
      "value":"America/Kentucky/Monticello",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":85
   },
   {
      "value":"America/Louisville",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":86
   },
   {
      "value":"America/Montreal",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":87
   },
   {
      "value":"America/Nassau",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":88
   },
   {
      "value":"America/New_York",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":89
   },
   {
      "value":"America/Nipigon",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":90
   },
   {
      "value":"America/Pangnirtung",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":91
   },
   {
      "value":"America/Port-au-Prince",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":92
   },
   {
      "value":"America/Thunder_Bay",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":93
   },
   {
      "value":"America/Toronto",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":94
   },
   {
      "value":"EST5EDT",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":95
   },
   {
      "value":"America/Indiana/Marengo",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":96
   },
   {
      "value":"America/Indiana/Vevay",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":97
   },
   {
      "value":"America/Indianapolis",
      "offset":-4,
      "tz":"UTC-05:00",
      "id":98
   },
   {
      "value":"America/Caracas",
      "offset":-4.5,
      "tz":"UTC-04:30",
      "id":99
   },
   {
      "value":"America/Asuncion",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":100
   },
   {
      "value":"America/Glace_Bay",
      "offset":-3,
      "tz":"UTC-04:00",
      "id":101
   },
   {
      "value":"America/Goose_Bay",
      "offset":-3,
      "tz":"UTC-04:00",
      "id":102
   },
   {
      "value":"America/Halifax",
      "offset":-3,
      "tz":"UTC-04:00",
      "id":103
   },
   {
      "value":"America/Moncton",
      "offset":-3,
      "tz":"UTC-04:00",
      "id":104
   },
   {
      "value":"America/Thule",
      "offset":-3,
      "tz":"UTC-04:00",
      "id":105
   },
   {
      "value":"Atlantic/Bermuda",
      "offset":-3,
      "tz":"UTC-04:00",
      "id":106
   },
   {
      "value":"America/Campo_Grande",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":107
   },
   {
      "value":"America/Cuiaba",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":108
   },
   {
      "value":"America/Anguilla",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":109
   },
   {
      "value":"America/Antigua",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":110
   },
   {
      "value":"America/Aruba",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":111
   },
   {
      "value":"America/Barbados",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":112
   },
   {
      "value":"America/Blanc-Sablon",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":113
   },
   {
      "value":"America/Boa_Vista",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":114
   },
   {
      "value":"America/Curacao",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":115
   },
   {
      "value":"America/Dominica",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":116
   },
   {
      "value":"America/Grand_Turk",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":117
   },
   {
      "value":"America/Grenada",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":118
   },
   {
      "value":"America/Guadeloupe",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":119
   },
   {
      "value":"America/Guyana",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":120
   },
   {
      "value":"America/Kralendijk",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":121
   },
   {
      "value":"America/La_Paz",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":122
   },
   {
      "value":"America/Lower_Princes",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":123
   },
   {
      "value":"America/Manaus",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":124
   },
   {
      "value":"America/Marigot",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":125
   },
   {
      "value":"America/Martinique",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":126
   },
   {
      "value":"America/Montserrat",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":127
   },
   {
      "value":"America/Port_of_Spain",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":128
   },
   {
      "value":"America/Porto_Velho",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":129
   },
   {
      "value":"America/Puerto_Rico",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":130
   },
   {
      "value":"America/Santo_Domingo",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":131
   },
   {
      "value":"America/St_Barthelemy",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":132
   },
   {
      "value":"America/St_Kitts",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":133
   },
   {
      "value":"America/St_Lucia",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":134
   },
   {
      "value":"America/St_Thomas",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":135
   },
   {
      "value":"America/St_Vincent",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":136
   },
   {
      "value":"America/Tortola",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":137
   },
   {
      "value":"Etc/GMT+4",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":138
   },
   {
      "value":"America/Santiago",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":139
   },
   {
      "value":"Antarctica/Palmer",
      "offset":-4,
      "tz":"UTC-04:00",
      "id":140
   },
   {
      "value":"America/St_Johns",
      "offset":-2.5,
      "tz":"UTC-03:30",
      "id":141
   },
   {
      "value":"America/Sao_Paulo",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":142
   },
   {
      "value":"America/Argentina/La_Rioja",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":143
   },
   {
      "value":"America/Argentina/Rio_Gallegos",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":144
   },
   {
      "value":"America/Argentina/Salta",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":145
   },
   {
      "value":"America/Argentina/San_Juan",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":146
   },
   {
      "value":"America/Argentina/San_Luis",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":147
   },
   {
      "value":"America/Argentina/Tucuman",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":148
   },
   {
      "value":"America/Argentina/Ushuaia",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":149
   },
   {
      "value":"America/Buenos_Aires",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":150
   },
   {
      "value":"America/Catamarca",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":151
   },
   {
      "value":"America/Cordoba",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":152
   },
   {
      "value":"America/Jujuy",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":153
   },
   {
      "value":"America/Mendoza",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":154
   },
   {
      "value":"America/Araguaina",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":155
   },
   {
      "value":"America/Belem",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":156
   },
   {
      "value":"America/Cayenne",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":157
   },
   {
      "value":"America/Fortaleza",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":158
   },
   {
      "value":"America/Maceio",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":159
   },
   {
      "value":"America/Paramaribo",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":160
   },
   {
      "value":"America/Recife",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":161
   },
   {
      "value":"America/Santarem",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":162
   },
   {
      "value":"Antarctica/Rothera",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":163
   },
   {
      "value":"Atlantic/Stanley",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":164
   },
   {
      "value":"Etc/GMT+3",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":165
   },
   {
      "value":"America/Godthab",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":166
   },
   {
      "value":"America/Montevideo",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":167
   },
   {
      "value":"America/Bahia",
      "offset":-3,
      "tz":"UTC-03:00",
      "id":168
   },
   {
      "value":"America/Noronha",
      "offset":-2,
      "tz":"UTC-02:00",
      "id":169
   },
   {
      "value":"Atlantic/South_Georgia",
      "offset":-2,
      "tz":"UTC-02:00",
      "id":170
   },
   {
      "value":"Etc/GMT+2",
      "offset":-2,
      "tz":"UTC-02:00",
      "id":171
   },
   {
      "value":"America/Scoresbysund",
      "offset":0,
      "tz":"UTC-01:00",
      "id":172
   },
   {
      "value":"Atlantic/Azores",
      "offset":0,
      "tz":"UTC-01:00",
      "id":173
   },
   {
      "value":"Atlantic/Cape_Verde",
      "offset":-1,
      "tz":"UTC-01:00",
      "id":174
   },
   {
      "value":"Etc/GMT+1",
      "offset":-1,
      "tz":"UTC-01:00",
      "id":175
   },
   {
      "value":"Africa/Casablanca",
      "offset":1,
      "tz":"UTC",
      "id":176
   },
   {
      "value":"Africa/El_Aaiun",
      "offset":1,
      "tz":"UTC",
      "id":177
   },
   {
      "value":"America/Danmarkshavn",
      "offset":0,
      "tz":"UTC",
      "id":178
   },
   {
      "value":"Etc/GMT",
      "offset":0,
      "tz":"UTC",
      "id":179
   },
   {
      "value":"Europe/Isle_of_Man",
      "offset":0,
      "tz":"UTC",
      "id":180
   },
   {
      "value":"Europe/Guernsey",
      "offset":0,
      "tz":"UTC",
      "id":181
   },
   {
      "value":"Europe/Jersey",
      "offset":0,
      "tz":"UTC",
      "id":182
   },
   {
      "value":"Europe/London",
      "offset":0,
      "tz":"UTC",
      "id":183
   },
   {
      "value":"Europe/Isle_of_Man",
      "offset":1,
      "tz":"UTC+01:00",
      "id":184
   },
   {
      "value":"Europe/Guernsey",
      "offset":1,
      "tz":"UTC+01:00",
      "id":185
   },
   {
      "value":"Europe/Jersey",
      "offset":1,
      "tz":"UTC+01:00",
      "id":186
   },
   {
      "value":"Europe/London",
      "offset":1,
      "tz":"UTC+01:00",
      "id":187
   },
   {
      "value":"Atlantic/Canary",
      "offset":1,
      "tz":"UTC",
      "id":188
   },
   {
      "value":"Atlantic/Faeroe",
      "offset":1,
      "tz":"UTC",
      "id":189
   },
   {
      "value":"Atlantic/Madeira",
      "offset":1,
      "tz":"UTC",
      "id":190
   },
   {
      "value":"Europe/Dublin",
      "offset":1,
      "tz":"UTC",
      "id":191
   },
   {
      "value":"Europe/Lisbon",
      "offset":1,
      "tz":"UTC",
      "id":192
   },
   {
      "value":"Africa/Abidjan",
      "offset":0,
      "tz":"UTC",
      "id":193
   },
   {
      "value":"Africa/Accra",
      "offset":0,
      "tz":"UTC",
      "id":194
   },
   {
      "value":"Africa/Bamako",
      "offset":0,
      "tz":"UTC",
      "id":195
   },
   {
      "value":"Africa/Banjul",
      "offset":0,
      "tz":"UTC",
      "id":196
   },
   {
      "value":"Africa/Bissau",
      "offset":0,
      "tz":"UTC",
      "id":197
   },
   {
      "value":"Africa/Conakry",
      "offset":0,
      "tz":"UTC",
      "id":198
   },
   {
      "value":"Africa/Dakar",
      "offset":0,
      "tz":"UTC",
      "id":199
   },
   {
      "value":"Africa/Freetown",
      "offset":0,
      "tz":"UTC",
      "id":200
   },
   {
      "value":"Africa/Lome",
      "offset":0,
      "tz":"UTC",
      "id":201
   },
   {
      "value":"Africa/Monrovia",
      "offset":0,
      "tz":"UTC",
      "id":202
   },
   {
      "value":"Africa/Nouakchott",
      "offset":0,
      "tz":"UTC",
      "id":203
   },
   {
      "value":"Africa/Ouagadougou",
      "offset":0,
      "tz":"UTC",
      "id":204
   },
   {
      "value":"Africa/Sao_Tome",
      "offset":0,
      "tz":"UTC",
      "id":205
   },
   {
      "value":"Atlantic/Reykjavik",
      "offset":0,
      "tz":"UTC",
      "id":206
   },
   {
      "value":"Atlantic/St_Helena",
      "offset":0,
      "tz":"UTC",
      "id":207
   },
   {
      "value":"Arctic/Longyearbyen",
      "offset":2,
      "tz":"UTC+01:00",
      "id":208
   },
   {
      "value":"Europe/Amsterdam",
      "offset":2,
      "tz":"UTC+01:00",
      "id":209
   },
   {
      "value":"Europe/Andorra",
      "offset":2,
      "tz":"UTC+01:00",
      "id":210
   },
   {
      "value":"Europe/Berlin",
      "offset":2,
      "tz":"UTC+01:00",
      "id":211
   },
   {
      "value":"Europe/Busingen",
      "offset":2,
      "tz":"UTC+01:00",
      "id":212
   },
   {
      "value":"Europe/Gibraltar",
      "offset":2,
      "tz":"UTC+01:00",
      "id":213
   },
   {
      "value":"Europe/Luxembourg",
      "offset":2,
      "tz":"UTC+01:00",
      "id":214
   },
   {
      "value":"Europe/Malta",
      "offset":2,
      "tz":"UTC+01:00",
      "id":215
   },
   {
      "value":"Europe/Monaco",
      "offset":2,
      "tz":"UTC+01:00",
      "id":216
   },
   {
      "value":"Europe/Oslo",
      "offset":2,
      "tz":"UTC+01:00",
      "id":217
   },
   {
      "value":"Europe/Rome",
      "offset":2,
      "tz":"UTC+01:00",
      "id":218
   },
   {
      "value":"Europe/San_Marino",
      "offset":2,
      "tz":"UTC+01:00",
      "id":219
   },
   {
      "value":"Europe/Stockholm",
      "offset":2,
      "tz":"UTC+01:00",
      "id":220
   },
   {
      "value":"Europe/Vaduz",
      "offset":2,
      "tz":"UTC+01:00",
      "id":221
   },
   {
      "value":"Europe/Vatican",
      "offset":2,
      "tz":"UTC+01:00",
      "id":222
   },
   {
      "value":"Europe/Vienna",
      "offset":2,
      "tz":"UTC+01:00",
      "id":223
   },
   {
      "value":"Europe/Zurich",
      "offset":2,
      "tz":"UTC+01:00",
      "id":224
   },
   {
      "value":"Europe/Belgrade",
      "offset":2,
      "tz":"UTC+01:00",
      "id":225
   },
   {
      "value":"Europe/Bratislava",
      "offset":2,
      "tz":"UTC+01:00",
      "id":226
   },
   {
      "value":"Europe/Budapest",
      "offset":2,
      "tz":"UTC+01:00",
      "id":227
   },
   {
      "value":"Europe/Ljubljana",
      "offset":2,
      "tz":"UTC+01:00",
      "id":228
   },
   {
      "value":"Europe/Podgorica",
      "offset":2,
      "tz":"UTC+01:00",
      "id":229
   },
   {
      "value":"Europe/Prague",
      "offset":2,
      "tz":"UTC+01:00",
      "id":230
   },
   {
      "value":"Europe/Tirane",
      "offset":2,
      "tz":"UTC+01:00",
      "id":231
   },
   {
      "value":"Africa/Ceuta",
      "offset":2,
      "tz":"UTC+01:00",
      "id":232
   },
   {
      "value":"Europe/Brussels",
      "offset":2,
      "tz":"UTC+01:00",
      "id":233
   },
   {
      "value":"Europe/Copenhagen",
      "offset":2,
      "tz":"UTC+01:00",
      "id":234
   },
   {
      "value":"Europe/Madrid",
      "offset":2,
      "tz":"UTC+01:00",
      "id":235
   },
   {
      "value":"Europe/Paris",
      "offset":2,
      "tz":"UTC+01:00",
      "id":236
   },
   {
      "value":"Europe/Sarajevo",
      "offset":2,
      "tz":"UTC+01:00",
      "id":237
   },
   {
      "value":"Europe/Skopje",
      "offset":2,
      "tz":"UTC+01:00",
      "id":238
   },
   {
      "value":"Europe/Warsaw",
      "offset":2,
      "tz":"UTC+01:00",
      "id":239
   },
   {
      "value":"Europe/Zagreb",
      "offset":2,
      "tz":"UTC+01:00",
      "id":240
   },
   {
      "value":"Africa/Algiers",
      "offset":1,
      "tz":"UTC+01:00",
      "id":241
   },
   {
      "value":"Africa/Bangui",
      "offset":1,
      "tz":"UTC+01:00",
      "id":242
   },
   {
      "value":"Africa/Brazzaville",
      "offset":1,
      "tz":"UTC+01:00",
      "id":243
   },
   {
      "value":"Africa/Douala",
      "offset":1,
      "tz":"UTC+01:00",
      "id":244
   },
   {
      "value":"Africa/Kinshasa",
      "offset":1,
      "tz":"UTC+01:00",
      "id":245
   },
   {
      "value":"Africa/Lagos",
      "offset":1,
      "tz":"UTC+01:00",
      "id":246
   },
   {
      "value":"Africa/Libreville",
      "offset":1,
      "tz":"UTC+01:00",
      "id":247
   },
   {
      "value":"Africa/Luanda",
      "offset":1,
      "tz":"UTC+01:00",
      "id":248
   },
   {
      "value":"Africa/Malabo",
      "offset":1,
      "tz":"UTC+01:00",
      "id":249
   },
   {
      "value":"Africa/Ndjamena",
      "offset":1,
      "tz":"UTC+01:00",
      "id":250
   },
   {
      "value":"Africa/Niamey",
      "offset":1,
      "tz":"UTC+01:00",
      "id":251
   },
   {
      "value":"Africa/Porto-Novo",
      "offset":1,
      "tz":"UTC+01:00",
      "id":252
   },
   {
      "value":"Africa/Tunis",
      "offset":1,
      "tz":"UTC+01:00",
      "id":253
   },
   {
      "value":"Etc/GMT-1",
      "offset":1,
      "tz":"UTC+01:00",
      "id":254
   },
   {
      "value":"Africa/Windhoek",
      "offset":1,
      "tz":"UTC+01:00",
      "id":255
   },
   {
      "value":"Asia/Nicosia",
      "offset":3,
      "tz":"UTC+02:00",
      "id":256
   },
   {
      "value":"Europe/Athens",
      "offset":3,
      "tz":"UTC+02:00",
      "id":257
   },
   {
      "value":"Europe/Bucharest",
      "offset":3,
      "tz":"UTC+02:00",
      "id":258
   },
   {
      "value":"Europe/Chisinau",
      "offset":3,
      "tz":"UTC+02:00",
      "id":259
   },
   {
      "value":"Asia/Beirut",
      "offset":3,
      "tz":"UTC+02:00",
      "id":260
   },
   {
      "value":"Africa/Cairo",
      "offset":2,
      "tz":"UTC+02:00",
      "id":261
   },
   {
      "value":"Asia/Damascus",
      "offset":3,
      "tz":"UTC+02:00",
      "id":262
   },
   {
      "value":"Europe/Helsinki",
      "offset":3,
      "tz":"UTC+02:00",
      "id":267
   },
   {
      "value":"Europe/Kiev",
      "offset":3,
      "tz":"UTC+02:00",
      "id":268
   },
   {
      "value":"Europe/Mariehamn",
      "offset":3,
      "tz":"UTC+02:00",
      "id":269
   },
   {
      "value":"Europe/Nicosia",
      "offset":3,
      "tz":"UTC+02:00",
      "id":270
   },
   {
      "value":"Europe/Riga",
      "offset":3,
      "tz":"UTC+02:00",
      "id":271
   },
   {
      "value":"Europe/Sofia",
      "offset":3,
      "tz":"UTC+02:00",
      "id":272
   },
   {
      "value":"Europe/Tallinn",
      "offset":3,
      "tz":"UTC+02:00",
      "id":273
   },
   {
      "value":"Europe/Uzhgorod",
      "offset":3,
      "tz":"UTC+02:00",
      "id":274
   },
   {
      "value":"Europe/Vilnius",
      "offset":3,
      "tz":"UTC+02:00",
      "id":275
   },
   {
      "value":"Europe/Zaporozhye",
      "offset":3,
      "tz":"UTC+02:00",
      "id":276
   },
   {
      "value":"Africa/Blantyre",
      "offset":2,
      "tz":"UTC+02:00",
      "id":277
   },
   {
      "value":"Africa/Bujumbura",
      "offset":2,
      "tz":"UTC+02:00",
      "id":278
   },
   {
      "value":"Africa/Gaborone",
      "offset":2,
      "tz":"UTC+02:00",
      "id":279
   },
   {
      "value":"Africa/Harare",
      "offset":2,
      "tz":"UTC+02:00",
      "id":280
   },
   {
      "value":"Africa/Johannesburg",
      "offset":2,
      "tz":"UTC+02:00",
      "id":281
   },
   {
      "value":"Africa/Kigali",
      "offset":2,
      "tz":"UTC+02:00",
      "id":282
   },
   {
      "value":"Africa/Lubumbashi",
      "offset":2,
      "tz":"UTC+02:00",
      "id":283
   },
   {
      "value":"Africa/Lusaka",
      "offset":2,
      "tz":"UTC+02:00",
      "id":284
   },
   {
      "value":"Africa/Maputo",
      "offset":2,
      "tz":"UTC+02:00",
      "id":285
   },
   {
      "value":"Africa/Maseru",
      "offset":2,
      "tz":"UTC+02:00",
      "id":286
   },
   {
      "value":"Africa/Mbabane",
      "offset":2,
      "tz":"UTC+02:00",
      "id":287
   },
   {
      "value":"Etc/GMT-2",
      "offset":2,
      "tz":"UTC+02:00",
      "id":288
   },
   {
      "value":"Europe/Istanbul",
      "offset":3,
      "tz":"UTC+03:00",
      "id":298
   },
   {
      "value":"Asia/Jerusalem",
      "offset":3,
      "tz":"UTC+02:00",
      "id":299
   },
   {
      "value":"Africa/Tripoli",
      "offset":2,
      "tz":"UTC+02:00",
      "id":300
   },
   {
      "value":"Asia/Amman",
      "offset":3,
      "tz":"UTC+03:00",
      "id":301
   },
   {
      "value":"Asia/Baghdad",
      "offset":3,
      "tz":"UTC+03:00",
      "id":302
   },
   {
      "value":"Europe/Kaliningrad",
      "offset":3,
      "tz":"UTC+02:00",
      "id":303
   },
   {
      "value":"Asia/Aden",
      "offset":3,
      "tz":"UTC+03:00",
      "id":304
   },
   {
      "value":"Asia/Bahrain",
      "offset":3,
      "tz":"UTC+03:00",
      "id":305
   },
   {
      "value":"Asia/Kuwait",
      "offset":3,
      "tz":"UTC+03:00",
      "id":306
   },
   {
      "value":"Asia/Qatar",
      "offset":3,
      "tz":"UTC+03:00",
      "id":307
   },
   {
      "value":"Asia/Riyadh",
      "offset":3,
      "tz":"UTC+03:00",
      "id":308
   },
   {
      "value":"Africa/Addis_Ababa",
      "offset":3,
      "tz":"UTC+03:00",
      "id":309
   },
   {
      "value":"Africa/Asmera",
      "offset":3,
      "tz":"UTC+03:00",
      "id":310
   },
   {
      "value":"Africa/Dar_es_Salaam",
      "offset":3,
      "tz":"UTC+03:00",
      "id":311
   },
   {
      "value":"Africa/Djibouti",
      "offset":3,
      "tz":"UTC+03:00",
      "id":312
   },
   {
      "value":"Africa/Juba",
      "offset":3,
      "tz":"UTC+03:00",
      "id":313
   },
   {
      "value":"Africa/Kampala",
      "offset":3,
      "tz":"UTC+03:00",
      "id":314
   },
   {
      "value":"Africa/Khartoum",
      "offset":3,
      "tz":"UTC+03:00",
      "id":315
   },
   {
      "value":"Africa/Mogadishu",
      "offset":3,
      "tz":"UTC+03:00",
      "id":316
   },
   {
      "value":"Africa/Nairobi",
      "offset":3,
      "tz":"UTC+03:00",
      "id":317
   },
   {
      "value":"Antarctica/Syowa",
      "offset":3,
      "tz":"UTC+03:00",
      "id":318
   },
   {
      "value":"Etc/GMT-3",
      "offset":3,
      "tz":"UTC+03:00",
      "id":319
   },
   {
      "value":"Indian/Antananarivo",
      "offset":3,
      "tz":"UTC+03:00",
      "id":320
   },
   {
      "value":"Indian/Comoro",
      "offset":3,
      "tz":"UTC+03:00",
      "id":321
   },
   {
      "value":"Indian/Mayotte",
      "offset":3,
      "tz":"UTC+03:00",
      "id":322
   },
   {
      "value":"Europe/Kirov",
      "offset":3,
      "tz":"UTC+03:00",
      "id":323
   },
   {
      "value":"Europe/Moscow",
      "offset":3,
      "tz":"UTC+03:00",
      "id":324
   },
   {
      "value":"Europe/Simferopol",
      "offset":3,
      "tz":"UTC+03:00",
      "id":325
   },
   {
      "value":"Europe/Volgograd",
      "offset":3,
      "tz":"UTC+03:00",
      "id":326
   },
   {
      "value":"Europe/Minsk",
      "offset":3,
      "tz":"UTC+03:00",
      "id":327
   },
   {
      "value":"Europe/Astrakhan",
      "offset":4,
      "tz":"UTC+04:00",
      "id":328
   },
   {
      "value":"Europe/Samara",
      "offset":4,
      "tz":"UTC+04:00",
      "id":329
   },
   {
      "value":"Europe/Ulyanovsk",
      "offset":4,
      "tz":"UTC+04:00",
      "id":330
   },
   {
      "value":"Asia/Tehran",
      "offset":4.5,
      "tz":"UTC+03:30",
      "id":331
   },
   {
      "value":"Asia/Dubai",
      "offset":4,
      "tz":"UTC+04:00",
      "id":332
   },
   {
      "value":"Asia/Muscat",
      "offset":4,
      "tz":"UTC+04:00",
      "id":333
   },
   {
      "value":"Etc/GMT-4",
      "offset":4,
      "tz":"UTC+04:00",
      "id":334
   },
   {
      "value":"Asia/Baku",
      "offset":5,
      "tz":"UTC+04:00",
      "id":335
   },
   {
      "value":"Indian/Mahe",
      "offset":4,
      "tz":"UTC+04:00",
      "id":336
   },
   {
      "value":"Indian/Mauritius",
      "offset":4,
      "tz":"UTC+04:00",
      "id":337
   },
   {
      "value":"Indian/Reunion",
      "offset":4,
      "tz":"UTC+04:00",
      "id":338
   },
   {
      "value":"Asia/Tbilisi",
      "offset":4,
      "tz":"UTC+04:00",
      "id":339
   },
   {
      "value":"Asia/Yerevan",
      "offset":4,
      "tz":"UTC+04:00",
      "id":340
   },
   {
      "value":"Asia/Kabul",
      "offset":4.5,
      "tz":"UTC+04:30",
      "id":341
   },
   {
      "value":"Antarctica/Mawson",
      "offset":5,
      "tz":"UTC+05:00",
      "id":342
   },
   {
      "value":"Asia/Aqtau",
      "offset":5,
      "tz":"UTC+05:00",
      "id":343
   },
   {
      "value":"Asia/Aqtobe",
      "offset":5,
      "tz":"UTC+05:00",
      "id":344
   },
   {
      "value":"Asia/Ashgabat",
      "offset":5,
      "tz":"UTC+05:00",
      "id":345
   },
   {
      "value":"Asia/Dushanbe",
      "offset":5,
      "tz":"UTC+05:00",
      "id":346
   },
   {
      "value":"Asia/Oral",
      "offset":5,
      "tz":"UTC+05:00",
      "id":347
   },
   {
      "value":"Asia/Samarkand",
      "offset":5,
      "tz":"UTC+05:00",
      "id":348
   },
   {
      "value":"Asia/Tashkent",
      "offset":5,
      "tz":"UTC+05:00",
      "id":349
   },
   {
      "value":"Etc/GMT-5",
      "offset":5,
      "tz":"UTC+05:00",
      "id":350
   },
   {
      "value":"Indian/Kerguelen",
      "offset":5,
      "tz":"UTC+05:00",
      "id":351
   },
   {
      "value":"Indian/Maldives",
      "offset":5,
      "tz":"UTC+05:00",
      "id":352
   },
   {
      "value":"Asia/Yekaterinburg",
      "offset":5,
      "tz":"UTC+05:00",
      "id":353
   },
   {
      "value":"Asia/Karachi",
      "offset":5,
      "tz":"UTC+05:00",
      "id":354
   },
   {
      "value":"Asia/Kolkata",
      "offset":5.5,
      "tz":"UTC+05:30",
      "id":355
   },
   {
      "value":"Asia/Calcutta",
      "offset":5.5,
      "tz":"UTC+05:30",
      "id":356
   },
   {
      "value":"Asia/Colombo",
      "offset":5.5,
      "tz":"UTC+05:30",
      "id":357
   },
   {
      "value":"Asia/Kathmandu",
      "offset":5.75,
      "tz":"UTC+05:45",
      "id":358
   },
   {
      "value":"Antarctica/Vostok",
      "offset":6,
      "tz":"UTC+06:00",
      "id":359
   },
   {
      "value":"Asia/Almaty",
      "offset":6,
      "tz":"UTC+06:00",
      "id":360
   },
   {
      "value":"Asia/Bishkek",
      "offset":6,
      "tz":"UTC+06:00",
      "id":361
   },
   {
      "value":"Asia/Qyzylorda",
      "offset":6,
      "tz":"UTC+06:00",
      "id":362
   },
   {
      "value":"Asia/Urumqi",
      "offset":6,
      "tz":"UTC+06:00",
      "id":363
   },
   {
      "value":"Etc/GMT-6",
      "offset":6,
      "tz":"UTC+06:00",
      "id":364
   },
   {
      "value":"Indian/Chagos",
      "offset":6,
      "tz":"UTC+06:00",
      "id":365
   },
   {
      "value":"Asia/Dhaka",
      "offset":6,
      "tz":"UTC+06:00",
      "id":366
   },
   {
      "value":"Asia/Thimphu",
      "offset":6,
      "tz":"UTC+06:00",
      "id":367
   },
   {
      "value":"Asia/Rangoon",
      "offset":6.5,
      "tz":"UTC+06:30",
      "id":368
   },
   {
      "value":"Indian/Cocos",
      "offset":6.5,
      "tz":"UTC+06:30",
      "id":369
   },
   {
      "value":"Antarctica/Davis",
      "offset":7,
      "tz":"UTC+07:00",
      "id":370
   },
   {
      "value":"Asia/Bangkok",
      "offset":7,
      "tz":"UTC+07:00",
      "id":371
   },
   {
      "value":"Asia/Hovd",
      "offset":7,
      "tz":"UTC+07:00",
      "id":372
   },
   {
      "value":"Asia/Jakarta",
      "offset":7,
      "tz":"UTC+07:00",
      "id":373
   },
   {
      "value":"Asia/Phnom_Penh",
      "offset":7,
      "tz":"UTC+07:00",
      "id":374
   },
   {
      "value":"Asia/Pontianak",
      "offset":7,
      "tz":"UTC+07:00",
      "id":375
   },
   {
      "value":"Asia/Saigon",
      "offset":7,
      "tz":"UTC+07:00",
      "id":376
   },
   {
      "value":"Asia/Vientiane",
      "offset":7,
      "tz":"UTC+07:00",
      "id":377
   },
   {
      "value":"Etc/GMT-7",
      "offset":7,
      "tz":"UTC+07:00",
      "id":378
   },
   {
      "value":"Indian/Christmas",
      "offset":7,
      "tz":"UTC+07:00",
      "id":379
   },
   {
      "value":"Asia/Novokuznetsk",
      "offset":7,
      "tz":"UTC+07:00",
      "id":380
   },
   {
      "value":"Asia/Novosibirsk",
      "offset":7,
      "tz":"UTC+07:00",
      "id":381
   },
   {
      "value":"Asia/Omsk",
      "offset":7,
      "tz":"UTC+07:00",
      "id":382
   },
   {
      "value":"Asia/Hong_Kong",
      "offset":8,
      "tz":"UTC+08:00",
      "id":383
   },
   {
      "value":"Asia/Macau",
      "offset":8,
      "tz":"UTC+08:00",
      "id":384
   },
   {
      "value":"Asia/Shanghai",
      "offset":8,
      "tz":"UTC+08:00",
      "id":385
   },
   {
      "value":"Asia/Krasnoyarsk",
      "offset":8,
      "tz":"UTC+08:00",
      "id":386
   },
   {
      "value":"Asia/Brunei",
      "offset":8,
      "tz":"UTC+08:00",
      "id":387
   },
   {
      "value":"Asia/Kuala_Lumpur",
      "offset":8,
      "tz":"UTC+08:00",
      "id":388
   },
   {
      "value":"Asia/Kuching",
      "offset":8,
      "tz":"UTC+08:00",
      "id":389
   },
   {
      "value":"Asia/Makassar",
      "offset":8,
      "tz":"UTC+08:00",
      "id":390
   },
   {
      "value":"Asia/Manila",
      "offset":8,
      "tz":"UTC+08:00",
      "id":391
   },
   {
      "value":"Asia/Singapore",
      "offset":8,
      "tz":"UTC+08:00",
      "id":392
   },
   {
      "value":"Etc/GMT-8",
      "offset":8,
      "tz":"UTC+08:00",
      "id":393
   },
   {
      "value":"Antarctica/Casey",
      "offset":8,
      "tz":"UTC+08:00",
      "id":394
   },
   {
      "value":"Australia/Perth",
      "offset":8,
      "tz":"UTC+08:00",
      "id":395
   },
   {
      "value":"Asia/Taipei",
      "offset":8,
      "tz":"UTC+08:00",
      "id":396
   },
   {
      "value":"Asia/Choibalsan",
      "offset":8,
      "tz":"UTC+08:00",
      "id":397
   },
   {
      "value":"Asia/Ulaanbaatar",
      "offset":8,
      "tz":"UTC+08:00",
      "id":398
   },
   {
      "value":"Asia/Irkutsk",
      "offset":8,
      "tz":"UTC+08:00",
      "id":399
   },
   {
      "value":"Asia/Dili",
      "offset":9,
      "tz":"UTC+09:00",
      "id":400
   },
   {
      "value":"Asia/Jayapura",
      "offset":9,
      "tz":"UTC+09:00",
      "id":401
   },
   {
      "value":"Asia/Tokyo",
      "offset":9,
      "tz":"UTC+09:00",
      "id":402
   },
   {
      "value":"Etc/GMT-9",
      "offset":9,
      "tz":"UTC+09:00",
      "id":403
   },
   {
      "value":"Pacific/Palau",
      "offset":9,
      "tz":"UTC+09:00",
      "id":404
   },
   {
      "value":"Asia/Pyongyang",
      "offset":9,
      "tz":"UTC+09:00",
      "id":405
   },
   {
      "value":"Asia/Seoul",
      "offset":9,
      "tz":"UTC+09:00",
      "id":406
   },
   {
      "value":"Australia/Adelaide",
      "offset":9.5,
      "tz":"UTC+09:30",
      "id":407
   },
   {
      "value":"Australia/Broken_Hill",
      "offset":9.5,
      "tz":"UTC+09:30",
      "id":408
   },
   {
      "value":"Australia/Darwin",
      "offset":9.5,
      "tz":"UTC+09:30",
      "id":409
   },
   {
      "value":"Australia/Brisbane",
      "offset":10,
      "tz":"UTC+10:00",
      "id":410
   },
   {
      "value":"Australia/Lindeman",
      "offset":10,
      "tz":"UTC+10:00",
      "id":411
   },
   {
      "value":"Australia/Melbourne",
      "offset":10,
      "tz":"UTC+10:00",
      "id":412
   },
   {
      "value":"Australia/Sydney",
      "offset":10,
      "tz":"UTC+10:00",
      "id":413
   },
   {
      "value":"Antarctica/DumontDUrville",
      "offset":10,
      "tz":"UTC+10:00",
      "id":414
   },
   {
      "value":"Etc/GMT-10",
      "offset":10,
      "tz":"UTC+10:00",
      "id":415
   },
   {
      "value":"Pacific/Guam",
      "offset":10,
      "tz":"UTC+10:00",
      "id":416
   },
   {
      "value":"Pacific/Port_Moresby",
      "offset":10,
      "tz":"UTC+10:00",
      "id":417
   },
   {
      "value":"Pacific/Saipan",
      "offset":10,
      "tz":"UTC+10:00",
      "id":418
   },
   {
      "value":"Pacific/Truk",
      "offset":10,
      "tz":"UTC+10:00",
      "id":419
   },
   {
      "value":"Australia/Currie",
      "offset":10,
      "tz":"UTC+10:00",
      "id":420
   },
   {
      "value":"Australia/Hobart",
      "offset":10,
      "tz":"UTC+10:00",
      "id":421
   },
   {
      "value":"Asia/Chita",
      "offset":9,
      "tz":"UTC+09:00",
      "id":422
   },
   {
      "value":"Asia/Khandyga",
      "offset":9,
      "tz":"UTC+09:00",
      "id":423
   },
   {
      "value":"Asia/Yakutsk",
      "offset":9,
      "tz":"UTC+09:00",
      "id":424
   },
   {
      "value":"Antarctica/Macquarie",
      "offset":11,
      "tz":"UTC+11:00",
      "id":425
   },
   {
      "value":"Etc/GMT-11",
      "offset":11,
      "tz":"UTC+11:00",
      "id":426
   },
   {
      "value":"Pacific/Efate",
      "offset":11,
      "tz":"UTC+11:00",
      "id":427
   },
   {
      "value":"Pacific/Guadalcanal",
      "offset":11,
      "tz":"UTC+11:00",
      "id":428
   },
   {
      "value":"Pacific/Kosrae",
      "offset":11,
      "tz":"UTC+11:00",
      "id":429
   },
   {
      "value":"Pacific/Noumea",
      "offset":11,
      "tz":"UTC+11:00",
      "id":430
   },
   {
      "value":"Pacific/Ponape",
      "offset":11,
      "tz":"UTC+11:00",
      "id":431
   },
   {
      "value":"Asia/Sakhalin",
      "offset":11,
      "tz":"UTC+11:00",
      "id":432
   },
   {
      "value":"Asia/Ust-Nera",
      "offset":11,
      "tz":"UTC+11:00",
      "id":433
   },
   {
      "value":"Asia/Vladivostok",
      "offset":11,
      "tz":"UTC+11:00",
      "id":434
   },
   {
      "value":"Antarctica/McMurdo",
      "offset":12,
      "tz":"UTC+12:00",
      "id":435
   },
   {
      "value":"Pacific/Auckland",
      "offset":12,
      "tz":"UTC+12:00",
      "id":436
   },
   {
      "value":"Etc/GMT-12",
      "offset":12,
      "tz":"UTC+12:00",
      "id":437
   },
   {
      "value":"Pacific/Funafuti",
      "offset":12,
      "tz":"UTC+12:00",
      "id":438
   },
   {
      "value":"Pacific/Kwajalein",
      "offset":12,
      "tz":"UTC+12:00",
      "id":439
   },
   {
      "value":"Pacific/Majuro",
      "offset":12,
      "tz":"UTC+12:00",
      "id":440
   },
   {
      "value":"Pacific/Nauru",
      "offset":12,
      "tz":"UTC+12:00",
      "id":441
   },
   {
      "value":"Pacific/Tarawa",
      "offset":12,
      "tz":"UTC+12:00",
      "id":442
   },
   {
      "value":"Pacific/Wake",
      "offset":12,
      "tz":"UTC+12:00",
      "id":443
   },
   {
      "value":"Pacific/Wallis",
      "offset":12,
      "tz":"UTC+12:00",
      "id":444
   },
   {
      "value":"Pacific/Fiji",
      "offset":12,
      "tz":"UTC+12:00",
      "id":445
   },
   {
      "value":"Asia/Anadyr",
      "offset":12,
      "tz":"UTC+12:00",
      "id":446
   },
   {
      "value":"Asia/Kamchatka",
      "offset":12,
      "tz":"UTC+12:00",
      "id":447
   },
   {
      "value":"Asia/Magadan",
      "offset":12,
      "tz":"UTC+12:00",
      "id":448
   },
   {
      "value":"Asia/Srednekolymsk",
      "offset":12,
      "tz":"UTC+12:00",
      "id":449
   },
   {
      "value":"Etc/GMT-13",
      "offset":13,
      "tz":"UTC+13:00",
      "id":451
   },
   {
      "value":"Pacific/Enderbury",
      "offset":13,
      "tz":"UTC+13:00",
      "id":452
   },
   {
      "value":"Pacific/Fakaofo",
      "offset":13,
      "tz":"UTC+13:00",
      "id":453
   },
   {
      "value":"Pacific/Tongatapu",
      "offset":13,
      "tz":"UTC+13:00",
      "id":454
   },
   {
      "value":"Pacific/Apia",
      "offset":13,
      "tz":"UTC+13:00",
      "id":455
   }
];

const timeZones = [
    {
       "value":"Asia/Hong_Kong",
       "offset":8,
       "tz":"UTC+08:00",
       "id":383
    },
    {
       "value":"Asia/Tokyo",
       "offset":9,
       "tz":"UTC+09:00",
       "id":402
    },
];

export default timeZones;
