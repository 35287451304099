import React from 'react';

// assets
import * as icons from '@tabler/icons';

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const User = {
    id: 'screenany-menu-billing',
    title: 'Accounting',
    type: 'group',
    children: [
        {
            id: 'payment',
            title: 'Payment',
            type: 'item',
            // url: '/payment',
            url: '/coming-soon',
            icon: icons.IconCoin,
            breadcrumbs: false
        }
    ]
};

export default User;
