const companySizes = [
    { 
        id: 1,
        label: '1-10 employees'
    },
    { 
        id: 2,
        label: '11-20 employees'
    },
    { 
        id: 3,
        label: '21-50 employees'
    },
    { 
        id: 4,
        label: '51-100 employees'
    },
    { 
        id: 5,
        label: '100-250  employees'
    },
    { 
        id: 6,
        label: '250+ employees'
    }
];

export default companySizes;