import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Autocomplete, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { config, fetchApi } from 'utils/screenany';

// style constant
const useStyles = makeStyles((theme) => ({
    corporationSelector: {
        width: '300px',
        marginLeft: '16px'
    }
}));

const CorporationSelectorSection = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const screenanyUser = useSelector((state) => state.screenanyUser);

    const [disabled, setDisabled] = useState(false);

    const onChangeCorporationHandler = async (corporation) => {
        // update current corporation_id
        dispatch({
            type: '@screenanyUser/UPDATE_CURRENT_CORPORATION',
            payload: corporation.id
        });
        // get and update count of unread application
        const responseApplication = await fetchApi(`${config.apiBaseUrl}/application`, {
            filters: [{ read: 0 }],
        });
        dispatch({
            type: '@screenanyUser/UPDATE_UNREAD_APPLICATION',
            payload: (responseApplication.data) ? responseApplication.data.length : 0
        });
        // get and update count of unread ats application
        const responseAtsApplication = await fetchApi(`${config.apiBaseUrl}/ats-application`, {
            filters: [{ read: 0 }],
        });
        dispatch({
            type: '@screenanyUser/UPDATE_UNREAD_ATS_APPLICATION',
            payload: (responseAtsApplication.data) ? responseAtsApplication.data.length : 0
        });
        dispatch({
            type: '@screenanyUser/UPDATE_CURRENT_CAMPAIGN_COLLECTION_ID',
            payload: -1
        });
    };

    var current_corporation;
    screenanyUser.corporation_select_list.forEach((corporation) => {
        if (screenanyUser.current_corporation_id.toString() === corporation.id.toString()) {
            current_corporation = corporation;
        }
    });

    if (current_corporation === undefined || screenanyUser.length === 0) {
        return (<></>);
    }

    return (
        <Autocomplete
            disabled={screenanyUser.corporation_select_list.length===1 || !screenanyUser.corporation_select_list_enabled}
            className={`${classes.corporationSelector}`}
            disableClearable
            options={screenanyUser.corporation_select_list}
            value={current_corporation}
            onChange={(event, newValue) => { onChangeCorporationHandler(newValue); }}
            PaperComponent={({ children }) => (
                <Paper sx={{ "& .MuiAutocomplete-option": { p: 1.5 } }}>{children}</Paper>
            )}
            renderInput={(params) => <TextField {...params} label={screenanyUser.corporation_select_list.length===1?'':'Select Entity'} />}
        />
    );
};

export default CorporationSelectorSection;
