import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// project imports
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

import { config, fetchApi, userLogout } from 'utils/screenany';

// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    /*
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate]);
    */

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updateScreenAnyUser = (user) => {
        dispatch({
            type: '@screenanyUser/UPDATE_USER',
            payload: user
        });
    };
    const updateScreenAnyDataUserroles = (userroles) => {
        dispatch({
            type: '@screenanyData/UPDATE_USERROLES',
            payload: userroles
        });
    };

    // check if user is logged in
    const userAuth = async () => {
        const responseData = await fetchApi(`${config.apiBaseUrl}/auth`)
        const data = responseData.data;
        // if access_token is invalid redirect to login page
        if (data === null) {
            // navigate('/login/?session_timeout', { replace: true });
            userLogout();
            navigate('/logged-out', { replace: true });
        } else {
            // merge corporation and corporations into corporation_select_list
            var corporation_select_list = [data.user.corporation].concat(data.user.corporations || []);
            for (var key in corporation_select_list) {
                corporation_select_list[key].label = corporation_select_list[key].name;
            }
            data.user.corporation_select_list = corporation_select_list;
            // update screenanyUser in redux
            updateScreenAnyUser(data.user);
            // update screenanyData in redux
            updateScreenAnyDataUserroles(data.userroles);
        }
    };
    userAuth();

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
