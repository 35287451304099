import React, { lazy } from 'react';

// material-ui
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@material-ui/core';

// project imports
import Loadable from 'ui-component/Loadable';

// 404 routing
const NotFound = Loadable(lazy(() => import('views/pages/notfound')));

// ===========================|| AUTH ROUTING ||=========================== //

const NotFoundRoutes = {
    path: '*',
    element: (
        <NotFound />
    )
};

export default NotFoundRoutes;
