import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';
import MainCard from 'ui-component/cards/MainCard';

const ComingSoonBox = (props) => {
    const screenanyUser = useSelector((state) => state.screenanyUser);

    var current_corporation = null;
    screenanyUser.corporation_select_list.forEach((corporation) => {
        if (screenanyUser.current_corporation_id.toString() === corporation.id.toString()) {
            current_corporation = corporation;
        }
    });

    return (
        <>
            <MainCard title="Coming soon" sx={{ border: 0 }}>
                <Typography variant="body2">
                    The feature is currently under development.
                </Typography>
                <Typography variant="body2">
                    You are now staying at <strong>{current_corporation.label}</strong>
                </Typography>
            </MainCard>
        </>
    )
}

export default ComingSoonBox;
