import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// routings
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const TestDashboard = Loadable(lazy(() => import('views/pages/test-dashboard')));
const Corporation = Loadable(lazy(() => import('views/pages/corporation')));
const FrontPage = Loadable(lazy(() => import('views/pages/front-page')));
const SuperUser = Loadable(lazy(() => import('views/pages/super-user')));
const SuperUserDetail = Loadable(lazy(() => import('views/pages/super-user/SuperUserDetail')));
const Client = Loadable(lazy(() => import('views/pages/client')));
const ClientDetail = Loadable(lazy(() => import('views/pages/client/ClientDetail')));
const User = Loadable(lazy(() => import('views/pages/user')));
const UserDetail = Loadable(lazy(() => import('views/pages/user/UserDetail')));
const Campaign = Loadable(lazy(() => import('views/pages/campaign')));
const CampaignDetail = Loadable(lazy(() => import('views/pages/campaign/CampaignDetail')));
const VideoFormBuilder = Loadable(lazy(() => import('views/pages/video-form-builder')));
const VideoApplication = Loadable(lazy(() => import('views/pages/application')));
// const AtsApplication = Loadable(lazy(() => import('views/pages/application-ats')));
const Field = Loadable(lazy(() => import('views/pages/field')));
const Billing = Loadable(lazy(() => import('views/pages/billing')));
const Profile = Loadable(lazy(() => import('views/pages/profile')));
const ComingSoon = Loadable(lazy(() => import('views/pages/coming-soon')));
const TestPage = Loadable(lazy(() => import('views/pages/test-page')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/test-dashboard',
            element: <TestDashboard />
        },
        {
            path: '/super-user',
            element: <SuperUser />
        },
        {
            path: '/super-user/:uuid',
            element: <SuperUserDetail />
        },
        {
            path: '/client',
            element: <Client />
        },
        {
            path: '/client/:id',
            element: <ClientDetail />
        },
        {
            path: '/user',
            element: <User />
        },
        {
            path: '/user/:uuid',
            element: <UserDetail />
        },
        {
            path: '/corporation',
            element: <Corporation />
        },
        {
            path: '/front-page',
            element: <FrontPage />
        },
        {
            path: '/application-video',
            element: <VideoApplication />
        },
        /*
        {
            path: '/application-ats',
            element: <AtsApplication />
        },
        */
        {
            path: '/campaign',
            element: <Campaign />
        },
        {
            path: '/campaign/:uuid',
            element: <CampaignDetail />
        },
        {
            path: '/video-form-builder/:uuid',
            element: <VideoFormBuilder />
        },
        {
            path: '/field',
            element: <Field />
        },
        {
            path: '/billing',
            element: <Billing />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/coming-soon',
            element: <ComingSoon />
        },
        {
            path: '/test-page',
            element: <TestPage />
        }
    ]
};

export default MainRoutes;
