const snackbar = (variant='error', message='No message set.', autoHideDuration=3000) => {
    return {
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        variant: 'alert',
        alertSeverity: variant,
        autoHideDuration: autoHideDuration,
        message: message
    };
};

export default snackbar;
