import Logo from 'assets/images/logo.png';
import LogoH from 'assets/images/logoh.png';
import LogoV from 'assets/images/logov.png';

const getLogo = (type, width='100%', height='100%', child) => {
    let logo;
    switch (type) {
        default:
            logo = (<img src={Logo} alt="Screenany" height={height} width={width} />); break;
        case 'v':
            logo = (<img src={LogoV} alt="Screenany" height={height} width={width} />); break;
        case 'h':
            logo = (<img src={LogoH} alt="Screenany" height={height} width={width} />); break;
    }
    return logo;
};

export default getLogo;
