import React from 'react';

// assets
import * as icons from '@tabler/icons';

const Root = {
    id: 'screenany-menu-root',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'test-dashboard',
        //     title: 'Test Dashboard',
        //     type: 'item',
        //     url: '/test-dashboard',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // }
    ]
};

export default Root;
